
import { ProductFilter, Product } from '@/models/Product';
import { generalStore } from '@/store';
import { computed, defineComponent, ref } from 'vue';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import useProduct from '@/modules/useProduct';
import { onActivated } from 'vue';
import { useRoute } from 'vue-router';
import Q from 'q';
import { useI18n } from 'vue-i18n';
import { ProductType } from '@/models/Interfaces';

export default defineComponent({
    name: 'Catalog',
    beforeRouteEnter(to, from, next) {
        if ((from.name != 'modal-catalog-filter' && from.name != 'product') || to.params.newSearch) {
            to.params.newSearch = '1';
        } else {
            to.params.newSearch = '';
        }
        next();
    },
    async setup() {
        const { t } = useI18n();
        const { getProduct } = useProduct();
        const route = useRoute();
        const products = ref<Product[]>([]);
        const filter = ref<ProductFilter>(new ProductFilter());
        filter.value = generalStore.getters.getCatalogFilter;
        if (!generalStore.state.productTypes.length) generalStore.dispatch('loadAllProductTypes');
        const productTypes = computed<ProductType[]>(() => generalStore.getters.getProductTypes);
        const productTotal = ref(0);
        const showOnlyActiveProducts = ref(true);
        function getProductType(typeId: string) {
            const type = productTypes.value.find(x => x.id == typeId);
            if (type) {
                products.value.map(p => {
                    if (p.productTypeId == type.id) {
                        p.productTypeName = type.name;
                    }
                });
                return type.name;
            }
            return '';
        }
        const hasFilter = computed<boolean>(() => {
            if (filter.value.productName) {
                return true;
            }
            if (filter.value.typeId) {
                return true;
            }
            return false;
        });
        const ShowFilter = () => {
            generalStore.commit('updateCatalogFilter', filter.value);
            router.push({ name: 'modal-catalog-filter' });
        };
        const sortAsc = ref(true);
        const sortParam = ref('Id');
        function sortBothWay(param: string) {
            if (sortParam.value == param) {
                sortAsc.value = !sortAsc.value;
                products.value.reverse();
                return;
            } else {
                sortParam.value = param;
                sortAsc.value = true;
                switch (sortParam.value) {
                    case 'Id':
                        return products.value.sort((item1: Product, item2: Product) => {
                            return Number(item1.idNoHash) > Number(item2.idNoHash) ? 1 : -1;
                        });
                    case 'Name':
                        return products.value.sort((item1: Product, item2: Product) => {
                            return item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1;
                        });
                    case 'Type':
                        return products.value.sort((item1: Product, item2: Product) => {
                            if (item1.productTypeName && item2.productTypeName) {
                                return item1.productTypeName.toLowerCase() > item2.productTypeName.toLowerCase() ? 1 : -1;
                            } else {
                                return 0;
                            }
                        });
                    default:
                        return products.value;
                }
            }
        }
        async function getProducts() {
            swal.showLoading();
            filter.value.status = showOnlyActiveProducts.value;
            const apiResult = await api.getProducts(filter.value);
            await Q.delay(400);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                return;
            }
            if (apiResult.data) {
                products.value = apiResult.data?.products;
                productTotal.value = apiResult.data?.total;
            }
            swal.close();
        }
        async function onActivatedAction() {
            filter.value = generalStore.getters.getCatalogFilter;
            if (route.params.newSearch) {
                if (!route.params.keepFilter) {
                    generalStore.commit('updateCatalogFilter', new ProductFilter());
                    filter.value = new ProductFilter();
                }
                products.value = [];
                await getProducts();
            }
        }
        onActivated(onActivatedAction);

        if (!products.value.length) {
            await getProducts();
        }
        async function routeToProduct(productId: string) {
            await getProduct(productId);
            router.push({ name: 'product', params: { id: productId, fromCatalog: 'yes' } });
        }
        async function GoCreateProductStepOne() {
            swal.showLoading();
            const apiResult = await api.CanCreateProduct();
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                swal.close();
                router.push({ name: 'createProductStepOne', params: { subStep: 1 } });
            } else {
                swal.fire({ icon: 'error', text: t('product.warning.limit-products') });
                return;
            }
        }

        const createProductCopy = async (productId: string) => {
            swal.showLoading();
            await Q.delay(400);
            const copiedProductResult = await api.CreateProductCopy(productId);
            if (copiedProductResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: copiedProductResult.errorMessage });
                return;
            }
            if (copiedProductResult.data) {
                await routeToProduct(copiedProductResult.data.id);
            }
        };
        function anyEmptyMandatoryFields(product: Product): boolean {
            // name, shortDescription, longDescription, regions, city, address, type, workingDate, priceList
            let anyMandatoryFieldIsEmpty = false;
            if (product.name == null) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (!product.shortDescription) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (!product.fullDescription) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (!product.cityId) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (!product.address) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (!product.productTypeId) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (product.productRegions.length == 0) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (product.productPrices.length == 0) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (product.productSlots.length == 0) {
                anyMandatoryFieldIsEmpty = true;
            }
            if (product.productImages && product.productImages.length == 0) {
                anyMandatoryFieldIsEmpty = true;
            }
            return anyMandatoryFieldIsEmpty;
        }

        async function showAll() {
            generalStore.commit('updateCatalogFilter', new ProductFilter());
            filter.value = new ProductFilter();
            await getProducts();
        }

        sortBothWay('Id');

        const getArrowClass = (sort: string) => {
            if (sort == sortParam.value) {
                if (sortAsc.value) {
                    return 'top-sort-arrow';
                } else {
                    return 'bottom-sort-arrow';
                }
            }
            return 'standart-sort-arrow';
        };
        function showWarning(index: number) {
            document.getElementById('product-update-' + index)?.classList.remove('dp-none');
            document.getElementById('product-update-' + index)?.classList.add('dp-block');
        }
        function hideWarning(index: number) {
            document.getElementById('product-update-' + index)?.classList.remove('dp-block');
            document.getElementById('product-update-' + index)?.classList.add('dp-none');
        }

        return {
            productTotal,
            ShowFilter,
            hasFilter,
            filter,
            showAll,
            routeToProduct,
            products,
            getProducts,
            showOnlyActiveProducts,
            sortParam,
            getProductType,
            GoCreateProductStepOne,
            createProductCopy,
            anyEmptyMandatoryFields,
            sortBothWay,
            getArrowClass,
            sortAsc,
            showWarning,
            hideWarning
        };
    }
});
